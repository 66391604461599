<template>
    <v-card>
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2"
        >
          mdi-magnify
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="3"
              >
                <v-text-field
                  v-model="form.name"
                  :label="$vuetify.lang.t('$vuetify.equipmentTemplate.name')"
                  clearable
                />
              </v-col>

              <v-col
                cols="3"
              >
                <v-text-field
                  v-model="form.no"
                  :label="$vuetify.lang.t('$vuetify.equipmentTemplate.no')"
                  clearable
                />
              </v-col>

              <v-col
                cols="3"
              >
                <v-text-field
                  v-model="form.company"
                  :label="$vuetify.lang.t('$vuetify.equipmentTemplate.company')"
                  clearable
                />
              </v-col>

              <v-col
                cols="3"
              >
                <v-select
                  v-model="form.category"
                  :label="$vuetify.lang.t('$vuetify.equipmentTemplate.category')"
                  :items="categoryItems"
                  item-text="name"
                  item-value="value"
                  clearable
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-btn
                  class="mx-2 mt-2 float-right"
                  color="primary"
                  fab
                  dark
                  small
                  @click="handleSearch"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          name: null,
          no: null,
          company: null,
          type: null
        },
        categoryItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.leasing'),
            value: 'LEASING'
          },{
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.sale'),
            value: 'SALE'
          }
        ]
      }
    },
    computed: {
    },
    created () {
    },
    methods: {
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
